<template>
  <Dashboard />
</template>

<script>
import Dashboard from '@/components/Dashboard.vue'

export default {
  name: 'DashboardView',
  components: { Dashboard }
}
</script>