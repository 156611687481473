<template>
<div :class="{'py-5': !userPresent}">
  <authenticator>
    <template v-slot="{ user, signOut }">
      <MainNav :signOut="signOut" :user="user" />
      <AuthenticatedWrapper />
    </template>
  </authenticator>
</div>
</template>

<script setup>
  import { Authenticator } from "@aws-amplify/ui-vue";
  import "@aws-amplify/ui-vue/styles.css";

  import { Amplify } from 'aws-amplify';
  import awsconfig from './aws-exports';

  import "@coreui/coreui/scss/coreui.scss";

  Amplify.configure(awsconfig);
</script>

<script>
import MainNav from './components/MainNav.vue'
import AuthenticatedWrapper from './components/AuthenticatedWrapper.vue';

export default {
  name: 'App',
  components: { AuthenticatedWrapper, MainNav },
  computed: {
    userPresent () {
      return this.$store.state.user
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
