<template>
  <CContainer class="py-5">
    <CRow>
      <CCol>
        <ConfigSection />
      </CCol>
    </CRow>
    <div class="py-5"></div>
    <CRow>
      <CCol>
        <SmsHistory />
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import ConfigSection from '@/components/ConfigSection.vue'
import SmsHistory from './SmsHistory.vue';
import { CCol, CContainer, CRow } from '@coreui/vue';

export default {
  name: 'Dashboard',
  components: { ConfigSection, CContainer, CCol, CRow, SmsHistory },
  mounted () {
    this.$store.dispatch('loadDashboardSections')
  }
}
</script>