<template>
<CContainer class="py-5">
  <h3>Account Verification Status</h3>
  <p v-if="accountVerificationStatus === accountUnverifiedStatus">Please wait while your account is being verified for access. Please reach out at {{ supportEmail }} for queries.</p>
  <p v-if="accountVerificationStatus === accountVerifiedStatus">Account verified, you should be able to access Whoosh console. Please reach out at {{ supportEmail }} for queries.</p>
  <p v-if="accountVerificationStatus === accountRejectedStatus">Access to Whoosh console has not been approved. Please reach out at {{ supportEmail }} for queries.</p>
</CContainer>
</template>

<script>
import { ACCOUNT_UNVERIFIED_STATUS, ACCOUNT_APPROVED_STATUS, ACCOUNT_REJECTED_STATUS, SUPPORT_EMAIL } from '@/utils/constants'

export default {
  computed: {
    accountVerificationStatus () {
      return this.$store.state.accountVerificationStatus
    }
  },
  data () {
    return {
      accountVerifiedStatus: ACCOUNT_APPROVED_STATUS,
      accountUnverifiedStatus: ACCOUNT_UNVERIFIED_STATUS,
      accountRejectedStatus: ACCOUNT_REJECTED_STATUS,
      supportEmail: SUPPORT_EMAIL
    }
  },
}
</script>