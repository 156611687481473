<template>
  <div>
    <h3 class="pb-3">SMS History</h3>
    <CCard bodyWrapper v-if="configLoaded">
      <div v-if="messages.length">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Message SID</th>
              <th scope="col">Delivery Time</th>
              <th scope="col">To</th>
              <th scope="col">From</th>
              <th scope="col">Body</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="message of messages" :key="message.sid">
              <td>{{ message.sid }}</td>
              <td>{{ message.date_sent }}</td>
              <td>{{ message.to }}</td>
              <td>{{ message.from }}</td>
              <td>{{ message.body }}</td>
              <td v-if="message.status === 'failed'" :title="message.error_message">{{ message.status }}</td>
              <td v-else>{{ message.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="py-5">
        No messages found
      </div>
    </CCard>
    <div class="text-right py-3" v-if="configLoaded && messages.length && messagesLastEvaluatedKey">
          <CButton v-if="messagesLastEvaluatedKey" @click="loadNextPage">Next Page</CButton>
        </div>
  </div>
</template>

<script>
import { CButton } from '@coreui/vue'

export default {
    name: "SmsHistory",
    computed: {
        accountSID () {
            return this.$store.state.config.accountSID;
        },
        authToken () {
            return this.$store.state.config.authToken;
        },
        configLoaded () {
            return this.$store.state.config;
        },
        messages () {
            return this.$store.state.messages || [];
        },
        messagesLastEvaluatedKey () {
          return this.$store.state.messagesLastEvaluatedKey
        }
    },
    components: { CButton },
    methods: {
      async loadNextPage () {
        this.$store.dispatch('loadMessages', { exclusiveStartKey: this.messagesLastEvaluatedKey })
      }
    }
}
</script>