<template>
  <div v-if="configLoaded">
    <h3 class="pb-3">Credentials</h3>
    <strong>Account SID:&nbsp;</strong>
    <div class="credential-input d-inline-block">
      <TotCopyInput v-model="accountSID" :disabled="updatingApiCredentials" />
    </div>
    <div class="py-1"></div>
    <strong>Auth Token:&nbsp;</strong>
    <div class="credential-input d-inline-block">
      <TotCopyInput v-model="authToken" :disabled="updatingApiCredentials" />
    </div>
    <div class="py-1"></div>
    <TotButton :loading="updatingApiCredentials" @click="updateApiCredentials">
      <template #initial>Update Credentials</template>
      <template #loading>Updating Credentials&nbsp;</template>
    </TotButton>
    <div class="py-3"></div>
    <strong>From Number:&nbsp;</strong>
    <TotBadge :copyValue="'+18555371834'">+18555371834</TotBadge>
  </div>
</template>

<script>
import TotBadge from './common/tot-badge.vue'
import TotButton from './common/tot-button.vue'
import TotCopyInput from './common/tot-copy-input'

export default {
  name: "ConfigSection",
  computed: {
    accountSID: {
      get () {
        return this.$store.state.accountSID
      },
      set (newValue) {
        this.$store.commit('setAccountSID', newValue)
      }
    },
    authToken: {
      get () {
        return this.$store.state.authToken
      },
      set (newValue) {
        this.$store.commit('setAuthToken', newValue)
      }
    },
    configLoaded () {
      return this.$store.state.config
    },
  },
  components: { TotBadge, TotButton, TotCopyInput },
  data () {
    return {
      updatingApiCredentials: false
    }
  },
  methods: {
    async updateApiCredentials () {
      this.updatingApiCredentials = true
      await this.$store.dispatch('updateApiCredentials')
      this.updatingApiCredentials = false
    }
  }
}
</script>

<style>
.credential-input {
  width: 500px;
}
</style>
