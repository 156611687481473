<template>
<CInputGroup class="mb-3">
  <CFormInput
    :placeholder="placeholder" 
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
  <CButton type="button" color="secondary" variant="outline" @click="copyToClipboard">
    <font-awesome-icon class="tot-copy-icon" icon="fa-regular fa-copy" />
  </CButton>
</CInputGroup>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      copyBtnTooltipText: 'Copy'
    }
  },
  emits: ['update:modelValue'],
  computed: {
    copyBtnTooltip: {
      get () {
        return this.copyBtnTooltipText
      },
      set (newValue) {
        this.copyBtnTooltipText = newValue
      }
    }
  },
  methods: {
    async copyToClipboard () {
      if (this.modelValue) {
        await navigator.clipboard.writeText(this.modelValue)
        this.copyBtnTooltip = 'Copied!'
        setTimeout(() => {
          this.copyBtnTooltip = 'Copy'
        }, 2000)
      }
    }
  }
}
</script>
