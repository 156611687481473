<template>
<div v-if="optInConsentLoaded && accountVerificationStatusLoaded">
  <router-view v-if="optInConsented && accountVerificationStatus === accountVerifiedStatus" />
  <OptInConsent v-else-if="!optInConsented" />
  <AccountVerificationScreen v-else-if="optInConsented && showAccountVerificationScreen" />
</div>
</template>

<script>
import { ACCOUNT_UNVERIFIED_STATUS, ACCOUNT_APPROVED_STATUS, ACCOUNT_REJECTED_STATUS } from '@/utils/constants'
import AccountVerificationScreen from './AccountVerificationScreen.vue'
import OptInConsent from './OptInConsent.vue'

export default {
  components: { AccountVerificationScreen, OptInConsent },
  computed: {
    accountVerificationStatus () {
      return this.$store.state.accountVerificationStatus
    },
    accountVerificationStatusLoaded () {
      return this.$store.state.accountVerificationStatusLoaded
    },
    optInConsented () {
      return this.$store.state.optInConsented
    },
    optInConsentLoaded () {
      return this.$store.state.optInConsentLoaded
    },
    showAccountVerificationScreen () {
      return ([ACCOUNT_UNVERIFIED_STATUS, ACCOUNT_REJECTED_STATUS].indexOf(this.$store.state.accountVerificationStatus) > -1)
    }
  },
  data () {
    return {
      accountVerifiedStatus: ACCOUNT_APPROVED_STATUS
    }
  },
  mounted () {
    this.$store.dispatch('loadOptInConsent')
    this.$store.dispatch('loadAccountVerificationStatus')
  }
}
</script>