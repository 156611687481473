<template>
<div>
  <CNavbar
    expand="lg"
    color-scheme="dark"
    class="main-navbar-background py-0"
    placement="fixed-top"
    style="position: relative;"
  >
    <CContainer fluid>
      <CNavbarBrand href="#" class="py-2">
        <img :src="navbarLogo" height="30" class="d-inline-block align-top" />
        &nbsp;&nbsp;
        Whoosh
      </CNavbarBrand>
      <CNavbarNav class="h-100" style="position: relative;">
        <CNavItem :class="inactiveLinkClasses" style="box-sizing: border-box">
          <CNavLink
            href="#"
            @click="logout"
          >
            Logout
          </CNavLink>
        </CNavItem>
      </CNavbarNav>
    </CContainer>
  </CNavbar>
</div>
</template>
    
<script>
import totogiLogo from '../assets/totogi-logo-white-pink-rgb.webp'

export default {
  name: 'MainNav',
  data () {
    return {
      navItemClasses: 'py-1 px-3 border-bottom-4',
      navbarLogo: totogiLogo
    }
  },
  props: {
    signOut: Function,
    user: Object
  },
  computed: {
    accountsLinkClasses () {
      return this.isAccountsPage ? this.activeLinkClasses : this.inactiveLinkClasses
    },
    activeLinkClasses () {
      return `${this.navItemClasses} active-link-border`
    },
    demoDeviceLinkClasses () {
      return this.isDeviceDemoPage ? this.activeLinkClasses : this.inactiveLinkClasses
    },
    inactiveLinkClasses () {
      return `${this.navItemClasses} inactive-link-border`
    },
    isAccountsPage () {
      return this.$router.currentRoute.value.name === 'Accounts'
    },
    isDeviceDemoPage () {
      return this.$router.currentRoute.value.name === 'DeviceDemo'
    },
    isPlansPage () {
      return this.$router.currentRoute.value.name === 'Home'
    },
    isProfilePage () {
      return this.$router.currentRoute.value.name === 'Profile'
    },
    mcc: {
      get () {
        return this.$store.state.mcc
      },
      set (value) {
        this.$store.commit('updateMcc', value)
      }
    },
    mnc: {
      get () {
        return this.$store.state.mnc
      },
      set (value) {
        this.$store.commit('updateMnc', value)
      }
    },
    plansLinkClasses () {
      return this.isPlansPage ? this.activeLinkClasses : this.inactiveLinkClasses
    },
    profileLinkClasses () {
      return this.isProfilePage ? this.activeLinkClasses : this.inactiveLinkClasses
    },
    signedIn: function () {
      return this.$store.state.providerId
    }
  },
  mounted () {
    this.$store.commit('setUser', this.user)
  },
  methods: {
    logout () {
      this.signOut()
      this.$store.commit('setUser', null)
    }
  }
}
</script>

<style>
.main-navbar-background {
  background-color: #001D3D;
}

.active-link-border {
  border-bottom: solid;
  border-color: #FF4F58;
}

.inactive-link-border {
  border-bottom: solid;
  border-color: rgba(0, 0, 0, 0);
}
</style>
