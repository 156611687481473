<template>
  <CContainer class="py-5">
    <h3>Opt-in for Whoosh SMS Service</h3>
    <p>Confirm to opt-in for Whoosh SMS Service below</p>
    <p>
      <CFormCheck v-model="optInConsented" label="I opt-in to receive SMS messages from Whoosh SMS service" value="true" :inline="true" />
    </p>
    <TotButton @click="submitOptInConsent" :loading="submittingOptIn" :disabled="!optInConsented">
      <template #initial>Submit</template>
      <template #loading>Submitting&nbsp;</template>
    </TotButton>
  </CContainer>
</template>

<script>
import { CContainer, CFormCheck } from '@coreui/vue';
import TotButton from './common/tot-button.vue'

export default {
  name: 'OptInConsent',
  components: { CContainer, CFormCheck, TotButton },
  data () {
    return {
      submittingOptIn: false,
      optInConsented: false
    }
  },
  computed: {
    optInBeingSubmitted () {
      return this.submittingOptIn
    }
  },
  methods: {
    async submitOptInConsent () {
      this.submittingOptIn = true
      await this.$store.dispatch('submitOptInConsent', { optInConsented: this.optInConsented })
      this.submittingOptIn = false
    }
  }
}
</script>