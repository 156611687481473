import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import coreui from '@coreui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faCopy } from '@fortawesome/free-regular-svg-icons'

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig)

library.add(faCopy)

createApp(App).use(store).use(router).use(coreui).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
