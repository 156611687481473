import { createStore } from 'vuex'
import axios from 'axios'

import { BACKEND_BASE_URL, ACCOUNT_UNVERIFIED_STATUS } from '@/utils/constants'

export default createStore({
  state () {
    return {
      config: null,
      messages: [],
      messagesLastEvaluatedKey: null,
      user: null,
      accountVerificationStatus: ACCOUNT_UNVERIFIED_STATUS,
      accountVerificationStatusLoaded: false,
      optInConsented: false,
      optInConsentLoaded: false,
      accountSID: null,
      authToken: null,
      oldAccountSID: null,
      oldAuthToken: null
    }
  },
  getters: {},
  mutations: {
    setConfig (state, config) {
      state.config = config
      state.accountSID = config.accountSID
      state.authToken = config.authToken
      state.oldAccountSID = config.accountSID
      state.oldAuthToken = config.authToken
    },
    setMessages (state, data) {
      state.messages = data.messages
      state.messagesLastEvaluatedKey = data.lastEvaluatedKey
    },
    setAccountSID (state, accountSID) {
      state.accountSID = accountSID
    },
    setAuthToken (state, authToken) {
      state.authToken = authToken
    },
    setOldAccountSID (state, oldAccountSID) {
      state.oldAccountSID = oldAccountSID
    },
    setOldAuthToken (state, oldAuthToken) {
      state.oldAuthToken = oldAuthToken
    },
    setAccountVerificationStatus (state, accountVerificationStatus) {
      state.accountVerificationStatus = accountVerificationStatus
    },
    setAccountVerificationStatusLoaded (state, accountVerificationStatusLoaded) {
      state.accountVerificationStatusLoaded = accountVerificationStatusLoaded
    },
    setOptInConsented (state, optInConsented) {
      state.optInConsented = optInConsented
    },
    setOptInConsentLoaded (state, optInConsentLoaded) {
      state.optInConsentLoaded = optInConsentLoaded
    },
    setUser (state, user) {
      state.user = user
    }
  },
  actions: {
    async loadConfig (context) {
      const accessToken = context.state.user.signInUserSession.accessToken.jwtToken
      const response = await axios.get(
        `${BACKEND_BASE_URL}/ApiCredentials`,
        {
          headers: {
            Authorization: accessToken
          }
        }
      )
      context.commit('setConfig', response.data)
    },
    async loadDashboardSections (context) {
      await context.dispatch('loadConfig')
      await context.dispatch('loadMessages', {})
    },
    async loadAccountVerificationStatus (context) {
      const accessToken = context.state.user.signInUserSession.accessToken.jwtToken
      try {
        const result = await axios.get(
          `${BACKEND_BASE_URL}/AccountVerificationStatus`,
          {
            headers: {
              Authorization: accessToken
            }
          }
        )
        console.log('Account verification status', result, result.data)
        context.commit('setAccountVerificationStatus', result.data.verification_status)
      } catch (err) {
        console.error(err)
      }
      context.commit('setAccountVerificationStatusLoaded', true)
    },
    async loadOptInConsent (context) {
      const accessToken = context.state.user.signInUserSession.accessToken.jwtToken
      try {
        await axios.get(
          `${BACKEND_BASE_URL}/SmsOptInConsent`,
          {
            headers: {
              Authorization: accessToken
            }
          }
        )
        context.commit('setOptInConsented', true)
      } catch (err) {
        context.commit('setOptInConsented', false)
      }
      context.commit('setOptInConsentLoaded', true)
    },
    async loadMessages (context, { exclusiveStartKey }) {
      const accountSID = context.state.config.accountSID
      const authToken = context.state.config.authToken
      const response = await axios.get(
        `${BACKEND_BASE_URL}/Accounts/${accountSID}/Messages.json`,
        {
          headers: {
            Authorization: `Basic ${btoa(`${accountSID}:${authToken}`)}`
          },
          params: {
            exclusiveStartKey
          }
        }
      )
      context.commit('setMessages', response.data)
    },
    async submitOptInConsent (context, { optInConsented }) {
      if (optInConsented) {
        const accessToken = context.state.user.signInUserSession.accessToken.jwtToken
        await axios.post(
          `${BACKEND_BASE_URL}/SmsOptInConsent`,
          {},
          {
            headers: {
              Authorization: accessToken
            }
          }
        )
        context.commit('setOptInConsented', true)
      }
    },
    async updateApiCredentials (context) {
      const accessToken = context.state.user.signInUserSession.accessToken.jwtToken
      await axios.put(
        `${BACKEND_BASE_URL}/ApiCredentials`,
        {
          accountSID: context.state.accountSID,
          authToken: context.state.authToken, 
          oldAccountSID: context.state.oldAccountSID, 
          oldAuthToken: context.state.oldAuthToken
        },
        {
          headers: {
            Authorization: accessToken
          }
        }
      )
    }
  }
})